/* ONLY place styles here which are just relevant for web. (There will not be many.) */

body {
  overscroll-behavior-x: none;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: .7;
}

input {
  outline: none !important;
}

.VictoryContainer > svg {
  overflow: visible;
}